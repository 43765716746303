export const CashBankCoa = {
  ACC_CASH: 110100,
  ACC_BANK: 110200,
}
export const Coa = {
  //cash bound
  CASH_BOTTOM_BOUND: 110100,
  PETTY_CASH: 110102,
  CASH_UPPER_BOUND: 110200,
  //bank bound
  BANK_BOTTOM_BOUND: 110200,
  BANK_UPPER_BOUND: 110300,
  ACC_PIUTANG_DAGANG: 110300,
  ACC_PERSEDIAAN: 110400,

  ACC_RECEIVABLE: 110300,
  ACC_STOCK: 110400,
  // TODO might as well define bounds for other accounts
  ASSET_BOTTOM_BOUND: 110000,
  ACC_PENYUSUTAN_AWAL: 120000,
  ACC_PENYUSUTAN_AKHIR: 130000,
  ASSET_UPPER_BOUND: 200000,

  LIABILITY_BOTTOM_BOUND: 210000,
  ACC_HUTANG_DAGANG: 210100,
  ACC_HUTANG_PPN: 210200,
  ACC_HUTANG_PPH: 210300,
  ACC_HUTANG_NON_DAGANG: 210400,
  ACC_HUTANG_PIHAK_KETIGA: 220100,
  LIABILITY_UPPER_BOUND: 300000,

  EQUITY_BOTTOM_BOUND: 310000,
  ACC_AKM_LABA_RUGI: 330000,

  ACC_SUM_BALANCE_SHEETS: 399000,
  EQUITY_UPPER_BOUND: 400000,

  INCOME_BOTTOM_BOUND: 400000,
  INCOME_UPPER_BOUND: 500000,

  OPERATIONAL_INCOME_BOTTOM_BOUND: 400000,
  OPERATIONAL_INCOME_UPPER_BOUND: 430000,
  EXPENSE_DEPRECIATION_BOTTOM_BOUND: 440000,
  EXPENSE_DEPRECIATION_UPPER_BOUND: 450000,

  NON_OPERATIONAL_INCOME_BOTTOM_BOUND: 500000,
  NON_OPERATIONAL_INCOME_UPPER_BOUND: 520000,

  EXPENSE_BOTTOM_BOUND: 500000,
  EXPENSE_UPPER_BOUND: 600000,

  OPERATIONAL_EXPENSE_BOTTOM_BOUND: 430000,
  OPERATIONAL_EXPENSE_UPPER_BOUND: 500000,

  NON_OPERATIONAL_EXPENSE_BOTTOM_BOUND: 520000,
  ACC_LABA_RUGI: 540000,
  ACC_NET_PROFIT: 550000,
  NON_OPERATIONAL_EXPENSE_UPPER_BOUND: 600000,

  //fake accoun,just for help reporting
  BALANCE_SHEET_SUMMARY: 399000,
  TOTAL_SALES_REVENUE: 419990,
  TOTAL_COST_OF_GOOD_SOLD: 429900,
  GROSS_PROFIT: 429990,
  OPERATIONAL_EXPENSES: 429991,
  TOTAL_OPERATIONAL_EXPENSES: 499900,
  INCOME_AFTER_OPERATIONAL_EXPENSES: 499991,
  TOTAL_OTHER_INCOME_AND_EXPENSES: 519990,
  PROFIT_BEFORE_TAX: 540499,
  CORPORATE_TAX_PAYABLE: 540500,
  PROFIT_AFTER_TAX: 550000,
  TOTAL_SUMMARY: 699999,
}

export const Gender = {
  Male: 1,
  Female: 0,
}

export const MaritalStatus = {
  Single: 0,
  Married: 1,
  Divorced: 2,
}

export const UserStatus = {
  Pending: 0,
  Activated: 1,
  Deactivated: 2,
  Deleted: 3,
}

export const ProductType = {
  Product: 1,
  DirectMaterial: 2,
}

export const ProductionType = {
  NEW: 1,
  REPACK: 2,
  REPAIR: 3,
}

export const ProductSaleOrderStatus = {
  PENDING: 1,
  PAID: 2,
  PRINT: 3,
  SEND: 4,
  OK: 5,
}

export const BankTransaction = {
  DEPOSIT: 1,
  WITHDRAWAL: 2,
  ADMINISTRATIVE_COST: 3,
  INTEREST: 4,
  TRANSFER: 5,
}

export const CodeMarketerDept = 'DPT0000005'

export const UserStatusLabel = {
  [UserStatus.Pending]: 'Pending',
  [UserStatus.Activated]: 'Activated',
  [UserStatus.Deactivated]: 'Deactivated',
  [UserStatus.Deleted]: 'Deleted',
}

export const UserStatusColor = {
  [UserStatus.Pending]: 'warning',
  [UserStatus.Activated]: 'success',
  [UserStatus.Deactivated]: 'danger',
  [UserStatus.Deleted]: 'danger',
}

export const DeptCode = {
  CODE_DEPT_PENGAWAS: 'DPT0000001',
  CODE_DEPT_PENGURUS: 'DPT0000002',
  CODE_DEPT_MANAJER: 'DPT0000003',
  CODE_DEPT_KEUANGAN: 'DPT0000004',
  CODE_DEPT_PEMBELIAN: 'DPT0000005',
  CODE_DEPT_PEMASARAN: 'DPT0000006',
  CODE_DEPT_PERSONALIA: 'DPT0000007',
}

export const Style = {
  fontSize: 14,
  size: 'sm',
}

export const AppSetting = {
  GENERAL: 3,
  PRODUCT_PURCHASE: 1,
  PRODUCT_SALE: 2,

  //sales
  DIRECT_SALE: 21,
  USE_TAX: 22,
  SALES_OVER_PAY_INTO_ACCOUNT_RECEIVABLE: 23,
  ALL_CUSTOMER_OFFICE: 24,
  CHECK_STOCK_ON_SALE_TRX: 25,

  //purchase
  DIRECT_PURCHASE: 11,
  TAX_INTO_CGS: 12,
  SHIPPING_COST_INTO_CGS: 13,
  PURCHASE_OVER_PAY_INTO_DEBT: 14,

  //general
  SUPPLIER_BALANCE_CHECK: 31,
  CUSTOMER_BALANCE_CHECK: 32,
  USE_POINT: 33,
  DROPSHIP: 34,
  BEGINNING_BALANCE: 35,
  BACK_DATE: 36,
}

export const Marketplaces = {
  OFFLINE_STORE: 'MP00000001',
  INTERNAL_ONLINE_STORE: 'MP00000002',
  EXTERNAL_ONLINE_STORE1: 'MP00000003',
}

export const EmployeeTypes = {
  EMPLOYEE_TYPE: 1,
  TRUSTEE_TYPE: 2,
}

export const UserType = {
  USER_EMPLOYEE_TYPE: 1,
  USER_TRUSTEE_TYPE: 2,
  USER_MEMBER_TYPE: 3,
}
