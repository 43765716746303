import React from 'react'
import { InputGroup, Input, Button, Col, Row } from 'reactstrap'
import DatePicker from 'react-datepicker'
import is from 'is_js'
import NumberFormat from 'react-number-format'
import CIcon from '@coreui/icons-react'
import { cilCalendar, cilDelete, cilOptions, cilPlus, cilSearch } from '@coreui/icons'
import { Style } from '../constants'

export function AddonInput(props) {
  const { icon, ...restProps } = props
  return (
    <InputGroup size={Style.size} className="border border-primary">
      <Input
        //style={{ padding: 2, fontSize: 13 }}
        color="primary"
        bsSize={Style.size}
        className="form-control-plaintext text-primary"
        {...restProps}
      />
      <Button
        size={Style.size}
        //style={{ padding: 2, fontSize: 13 }}
        color="primary"
        //outline
        //className="btn btn-outline-secondary"
        type="button"
        id="button-addon2"
        onClick={props.onClick}
      >
        <CIcon icon={icon} />
      </Button>
    </InputGroup>
  )
}

export function SearchInput(props) {
  return (
    <AddonInput
      //style={{ padding: 2, fontSize: 13 }}
      color="primary"
      icon={cilSearch}
      type="search"
      className="form-control-plaintext text-primary"
      {...props}
    />
  )
}

export function SelectionInput(props) {
  const { onClick, onClear, onCreate, icon, ...restProps } = props
  return (
    <InputGroup size={Style.size} className="border border-primary">
      <Input
        color="primary"
        bsSize={Style.size}
        //style={{ padding: 2, fontSize: 13 }}
        onChange={() => {}}
        onClick={onClick}
        className="form-control-plaintext text-primary"
        {...restProps}
      />
      {onCreate ? (
        <Button
          //style={{ padding: 2, fontSize: 13 }}
          //outline
          color="primary"
          size={Style.size}
          onClick={onCreate}
        >
          <CIcon icon={icon ? icon : cilPlus} />
        </Button>
      ) : null}
      {onClear && restProps.value ? (
        <Button
          //style={{ padding: 2, fontSize: 13 }}
          outline
          color="danger"
          size={Style.size}
          onClick={onClear}
        >
          <CIcon icon={cilDelete} />
        </Button>
      ) : (
        <Button
          //style={{ padding: 2, fontSize: 13 }}
          //outline
          color="primary"
          size={Style.size}
          onClick={onClick}
        >
          <CIcon icon={icon ? icon : cilOptions} />
        </Button>
      )}
    </InputGroup>
  )
}

export function NumberInput(props) {
  const { onChange, ...rest } = props
  return (
    <NumberFormat
      size={Style.size}
      style={{ padding: 2, fontSize: 13 }}
      color="primary"
      customInput={Input}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      allowLeadingZeros={false}
      onValueChange={(values) => {
        const e = {
          target: {
            value: values.value,
          },
        }
        onChange(e)
      }}
      className="text-end text-primary"
      {...rest}
    />
  )
}

export function AbsoluteNumberInput(props) {
  return (
    <NumberFormat
      size={Style.size}
      style={{ padding: 2, fontSize: 13 }}
      color="primary"
      customInput={Input}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      allowLeadingZeros={false}
      className="text-end text-primary"
      {...props}
    />
  )
}

export function AbsoluteNumberInputLegacy(props) {
  const min = props.min ? props.min : 0
  return (
    <Input
      bsSize={Style.size}
      style={{ padding: 2, fontSize: 13 }}
      type="number"
      color="primary"
      className="text-end text-primary"
      min={min}
      {...props}
      onChange={(e) => {
        const val = e.target.value.replace(/^(0+)(\d{1,})/, (match, zeros, digits) => digits)

        e.target.value = val < 0 ? 0 : val

        props.onChange(e)
      }}
      onBlur={(e) => {
        const numval = +e.target.value
        if (is.not.number(numval) || numval < min) {
          e.target.value = min
        }

        props.onChange(e)
      }}
    />
  )
}

export function DateInput(props) {
  const { invalid, ...restProps } = props
  return (
    <DatePicker
      size={Style.size}
      style={{ padding: 2, fontSize: 13 }}
      color="primary"
      dateFormat="dd-MM-yyyy"
      customInput={<AddonInput invalid={invalid} icon={cilCalendar} />}
      className="d-block text-primary"
      {...restProps}
    />
  )
}

export function DateRange(props) {
  const { startDate, endDate, onChangeStartDate, onChangeEndDate, invalid, ...restProps } = props
  return (
    <Row>
      <Col md="6">
        <div className="mt-0 mb-1">
          <DatePicker
            size={Style.size}
            style={{ padding: 2, fontSize: 13 }}
            color="primary"
            dateFormat="dd-MM-yyyy"
            className="d-block text-primary"
            customInput={<AddonInput invalid={invalid} icon={cilCalendar} />}
            onChange={(date) => {
              if (date > endDate) {
                // prevent start date to exceed end date
                onChangeEndDate(date)
              }

              onChangeStartDate(date)
            }}
            selected={startDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            {...restProps}
          />
        </div>
      </Col>
      <Col md="6">
        <div className="mt-0 mb-1">
          <DatePicker
            size={Style.size}
            style={{ padding: 2, fontSize: 13 }}
            color="primary"
            dateFormat="dd-MM-yyyy"
            className="d-block text-primary"
            customInput={<AddonInput invalid={invalid} icon={cilCalendar} />}
            onChange={onChangeEndDate}
            selected={endDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            {...restProps}
          />
        </div>
      </Col>
    </Row>
  )
}
